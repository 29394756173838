<template lang="pug">
om-modal.money-format-modal(name="money-format-modal" color="light" width="50%")
  template(slot="modal-header")
    .d-flex
      .font-weight-bold Money format
    .brand-modal-action-icon
      span.cursor-pointer(
        @click="$modal.hide('money-format-modal')"
        title="close"
        aria-hidden="true"
      ) X
  template(slot="modal-body")
    .d-flex.gap-1
      om-input#samplePrice.w-7(v-model="samplePrice" type="number" label="Sample price")
      om-input#currency.w-5(v-model="currency" type="text" label="Currency")
      om-switch#beforeAfter.w-20(v-model="currencyUpfront" label="Currency position")
    .results.d-flex.align-items-center.mt-5.gap-2
      template(v-for="type in types")
        .format-type
          .font-weight-500 Price:
          .font-weight-bold {{ formatMoney(samplePrice * 100, type) }}
          om-button.mt-2(primary @click="onTypeSelect(type)") Use this
  template.p-0(slot="modal-footer")
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';

  const AMOUNT = 'amount';
  const AMOUNT_NO_DECIMALS = 'amount_no_decimals';
  const AMOUNT_WITH_COMMA_SEP = 'amount_with_comma_separator';
  const AMOUNT_NO_DECIMALS_WITH_COMMA_SEP = 'amount_no_decimals_with_comma_separator';

  export default {
    mixins: [itemMixin],
    data: () => ({
      samplePrice: 1349.89,
      currency: '$',
      currencyUpfront: false,
    }),
    computed: {
      ...mapState(['selectedElement']),
      ...mapGetters(['domains']),
      types() {
        return [
          AMOUNT,
          AMOUNT_NO_DECIMALS,
          AMOUNT_WITH_COMMA_SEP,
          AMOUNT_NO_DECIMALS_WITH_COMMA_SEP,
        ];
      },
    },
    methods: {
      onTypeSelect(type) {
        const moneyFormat = this.getStringBasedOnPosition(`{{${type}}}`);
        this.setValueOf(`selectedElement.data.moneyFormat`, moneyFormat);
        this.$modal.hide('money-format-modal');
      },
      getStringBasedOnPosition(price) {
        return this.currencyUpfront ? `${this.currency} ${price}` : `${price} ${this.currency}`;
      },
      formatMoney(cents, format) {
        const formatWithDelimiters = (number, precision = 2, thousands = ',', decimal = '.') => {
          if (isNaN(number) || number == null) {
            return 0;
          }

          number = (number / 100).toFixed(precision);

          const parts = number.split('.');
          const dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${thousands}`);
          const cents = parts[1] ? decimal + parts[1] : '';

          return dollars + cents;
        };

        if (format === AMOUNT_NO_DECIMALS) {
          return this.getStringBasedOnPosition(formatWithDelimiters(cents, 0));
        }

        if (format === AMOUNT_WITH_COMMA_SEP) {
          return this.getStringBasedOnPosition(formatWithDelimiters(cents, 2, '.', ','));
        }

        if (format === AMOUNT_NO_DECIMALS_WITH_COMMA_SEP) {
          return this.getStringBasedOnPosition(formatWithDelimiters(cents, 0, '.', ','));
        }

        return this.getStringBasedOnPosition(formatWithDelimiters(cents, 2));
      },
    },
  };
</script>
<style lang="sass">
  .money-format-modal
    .results
      justify-content: space-between
    .switch
      display: flex
      flex-direction: column
      margin-left: unset
      label
        margin-bottom: unset
    .gap-1
      gap: 1rem
    .gap-2
      gap: 2rem
</style>
